import React from "react";
import styled from "styled-components";

// Styled components for layout
const PageWrapper = styled.div`
  padding: 2rem;
  max-width: 900px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
`;

const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  color: #222;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #444;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 1.5rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
    background-color: #f1f1f1;
  }

  h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #555;
  }

  p {
    margin: 0;
    font-size: 1rem;
    color: #666;
  }
`;

const ExternalResourcesPage = () => {
  const players = [
    { name: "TiviMate", description: "A sleek IPTV player for Android users." },
    { name: "IPTV Smarters", description: "Supports multiple platforms and Xtream codes." },
    { name: "MYTVOnline3", description: "Optimized for Formuler devices." },
    { name: "XCIPTV", description: "Customizable IPTV player for Android and iOS." },
    { name: "Sparkle TV", description: "A versatile IPTV player for various systems." },
    { name: "NexTV", description: "User-friendly player for Android devices." },
    { name: "Perfect Player", description: "Feature-rich player for Windows and Android." },
    { name: "Televizo", description: "Simple and reliable player for Android." },
    { name: "OTT Navigator", description: "Advanced IPTV features for Android and Windows." },
    { name: "Kodi IPTV Client", description: "A versatile media player with Xtream support." },
    { name: "iMPlayer", description: "Clean and efficient player for Android." },
    { name: "IPTV Extreme Pro", description: "Powerful and feature-packed for Android." },
    { name: "Smart IPTV", description: "Widely available player for Smart TVs and more." },
  ];

  return (
    <PageWrapper>
      <Title>Xtream IPTV Players</Title>
      <Subtitle>Top Picks for Enhancing Your Xtream IPTV Experience</Subtitle>
      <List>
        {players.map((player, index) => (
          <ListItem key={index}>
            <h3>{index + 1}. {player.name}</h3>
            <p>{player.description}</p>
          </ListItem>
        ))}
      </List>
    </PageWrapper>
  );
};

export default ExternalResourcesPage;
