import React from "react";
import styled from "styled-components";

// Styled components for layout and responsiveness
const SupportWrapper = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  text-align: center;
  color: #222;
  margin-bottom: 1rem;
`;

const Section = styled.div`
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #444;
`;

const SectionText = styled.p`
  font-size: 1rem;
  margin: 0;
`;

const ContactInfo = styled.div`
  margin-top: 2rem;
  font-size: 1rem;
  text-align: center;
  color: #555;

  a {
    color: #1d72b8;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SupportPage = () => {
  return (
    <SupportWrapper>
      <Title>Welcome to the Xtream-Generator.com Support Page</Title>

      <Section>
        <SectionText>
          Here’s everything you need to know to get the best out of our services:
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>Regular Updates</SectionTitle>
        <SectionText>
          We update our server offerings regularly to ensure you have access to
          the best and most reliable servers available.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>Connection Limits</SectionTitle>
        <SectionText>
          Each server can have different maximum connections. Some servers may
          support only one connection at a time. If a server doesn't work for
          you, it might be because someone else is already using it. Simply
          generate a new server and try again.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>Expiration Dates</SectionTitle>
        <SectionText>
          Servers come with expiration dates, which means some may no longer be
          active. If a server doesn't work on your first attempt, don't get
          discouraged. Keep trying, as there are always new servers available
          that might work for longer periods.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>Language Variations</SectionTitle>
        <SectionText>
          Servers are randomly generated, so you might receive a server with
          content in a language different from your own. If this happens,
          continue generating servers until you find one that suits your
          preferences.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>Content Control</SectionTitle>
        <SectionText>
          We do not control the content of the servers. The servers are provided
          as-is by the providers. If you find a server's content unsatisfactory,
          simply generate a new one.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>Need Further Assistance?</SectionTitle>
        <SectionText>
          If you have any questions or need additional support, please don’t
          hesitate to contact us at{" "}
          <a href="mailto:Mohcin15000@gmail.com">Mohcin15000@gmail.com</a>.
        </SectionText>
      </Section>

      <ContactInfo>
        We’re here to help you enjoy the best of IPTV, free and legally, with
        Xtream-Generator.com.
      </ContactInfo>
    </SupportWrapper>
  );
};

export default SupportPage;
