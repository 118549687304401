import { Row, Col } from "antd";

import { SvgIcon } from "../common/SvgIcon";
import Container from "../common/Container";

import { Link } from "react-router-dom";

import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";





const Footer = () => {
 

  const SocialLink = ({ href, src }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      
        <FooterSection>
        <Container>
         <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{"External"}</Language>
              <Large as={Link} to="/external">{"External Resources"}</Large>
              <Para>
                {`Do you have any question? Feel free to reach out.`}
              </Para>
              <a href="mailto:l.qqbadze@gmail.com">
                <Chat>{`Let's Chat`}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{"Policy and Terms"}</Title>
              <Large as={Link} to="/privacy">
  {"Privacy and Policy"}
</Large>
<Large as={Link} to="/terms">
  {"Servive Terms"}
</Large>
<Large as={Link} to="/test">
  {"Servive Terms"}
</Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
            <Title>{"Support"}</Title>

            <Large as={Link} to="/support">
  {"Support"}
</Large>            </Col>
         </Row>

         
         
        </Container>

</ FooterSection>
      
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <img
                  src="logo-transparent.png"
                  aria-label="homepage"
                  width="150px"
               
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              
              <a
                href="https://xtream-generator.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                © 2024 Xtreme-Generator | IPTV Code Generator
              </a>
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default (Footer);
