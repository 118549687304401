import { useEffect } from "react";

function TestAds() {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense Error: ", e);
    }
  }, []);

  return (
    <div className="app">
      <div className="container">
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-9700554883020818"
          data-ad-slot="1217041105"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <p>Ad content will load above.</p>
      </div>


      <div
          id="full-screen-ad"
          style={{
            position: "fixed",
            top: 1000,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden", // Ensures the content stays inside the viewport
          }}
        >
          <div
            id="ad-container"
            style={{
              position: "relative",
              backgroundColor: "#fff", // Ad container background
              padding: "20px",
              borderRadius: "8px", // Rounded corners for a cleaner look
              width: "80%", // Adjust the size of the ad container
              maxWidth: "600px", // Set a maximum width for better responsiveness
            }}
          >
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9700554883020818"
              crossorigin="anonymous"
            ></script>

            <ins
              class="adsbygoogle"
              style={{
                display: "block",
                minHeight: "100vh",
                minWidth:"100vw"
              }}
              data-ad-client="ca-pub-9700554883020818"
              data-ad-slot="7678422922"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
            <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
            {/* Skip Ad Button */}
          
            {/* Add your ad content here */}
            <div id="ad-content">{/* Your ad content goes here */}</div>
          </div>
        </div>
    </div>
  );
}

export default TestAds;
