import React from "react";
import styled from "styled-components";

// Styled components for layout and responsiveness
const SupportWrapper = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  text-align: center;
  color: #222;
  margin-bottom: 1rem;
`;

const Section = styled.div`
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #444;
`;

const SectionText = styled.p`
  font-size: 1rem;
  margin: 0;
`;

const ContactInfo = styled.div`
  margin-top: 2rem;
  font-size: 1rem;
  text-align: center;
  color: #555;

  a {
    color: #1d72b8;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Terms = () => {
  return (
    <SupportWrapper>
      <Title>Terms of Service for Xtream-Generator.com</Title>

      <Section>
        <SectionText>
        Welcome to Xtream-Generator.com. Please read these Terms of Service ("Terms") carefully before using our website. By accessing or using our website, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our website        </SectionText>
      </Section>

      <Section>
        <SectionTitle>Acceptance of Terms</SectionTitle>
        <SectionText>
        By using Xtream-Generator.com, you agree to comply with and be bound by these Terms. We reserve the right to modify these Terms at any time. Your continued use of the website constitutes acceptance of any changes.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>Use of the Website</SectionTitle>
        <SectionText>
        Eligibility: You must be at least 18 years old to use Xtream-Generator.com. By using the website, you represent and warrant that you meet this requirement.

Account: You may be required to create an account to access certain features of the website. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.

Prohibited Conduct: You agree not to use the website for any unlawful purpose or in a manner that could damage, disable, overburden, or impair the website

        </SectionText>
      </Section>

      <Section>
        <SectionTitle>IPTV Server Generation</SectionTitle>
        <SectionText>
        Service Description: Xtream-Generator.com provides a tool to generate free, LEGAL IPTV servers. The generated servers include an HTTP URL, username, and password.

Usage: You are responsible for verifying the functionality and legality of the generated servers. Xtream-Generator.com is not responsible for any issues arising from the use of the servers.

        </SectionText>
      </Section>

      <Section>
        <SectionTitle>Intellectual Property</SectionTitle>
        <SectionText>
        Ownership: All content on Xtream-Generator.com, including text, graphics, logos, and software, is the property of Xtream-Generator.com or its content suppliers and is protected by intellectual property laws.

Restrictions: You may not reproduce, distribute, modify, or create derivative works from any content on the website without explicit permission from Xtream-Generator.com.
.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>Privacy</SectionTitle>
        <SectionText>
        Data Collection: We collect and use your personal information in accordance with our Privacy Policy. Please review our Privacy Policy to understand our practices.

Security: We take reasonable measures to protect your personal information but cannot guarantee its absolute security.

        </SectionText>
      </Section>

      <Section>
        <SectionTitle>Disclaimers and Limitation of Liability</SectionTitle>
        <SectionText>
        No Warranty: Xtream-Generator.com is provided "as is" and "as available" without any warranties of any kind, either express or implied.

Limitation of Liability: Xtream-Generator.com shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of the website.

        </SectionText>
      </Section>

      <Section>
        <SectionTitle>Indemnification</SectionTitle>
        <SectionText>
        You agree to indemnify and hold harmless Xtream-Generator.com, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses arising from your use of the website or violation of these Terms.
        </SectionText>
      </Section>



      <Section>
        <SectionTitle>Governing Law</SectionTitle>
        <SectionText>
        These Terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles        </SectionText>
      </Section>



      <Section>

        
        <SectionTitle>Need Further Assistance?</SectionTitle>
        <SectionText>
          If you have any questions or need additional support, please don’t
          hesitate to contact us at{" "}
          <a href="mailto:Mohcin15000@gmail.com">Mohcin15000@gmail.com</a>.
        </SectionText>
      </Section>

      <ContactInfo>
        We’re here to help you enjoy the best of IPTV, free and legally, with
        Xtream-Generator.com.
      </ContactInfo>
    </SupportWrapper>
  );
};

export default Terms;
