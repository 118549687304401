import { lazy } from "react";

import ContactContent from "../content/ContactContent.json";

const Contact = lazy(() => import("../ContactForm"));

const Container = lazy(() => import("../common/Container"));
const ScrollToTop = lazy(() => import("../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../ContentBlock"));
const OurMissionBlock = lazy(() => import("../ContentBlock/OurMissionBlock"));
const HowtoUseBlock = lazy(() => import("../ContentBlock/HowToUseBlock"));
const PrivacyPolicyBlock = lazy(() => import("../ContentBlock/PrivacyPolicy"));
const TestomonialBlock  = lazy(() => import("../ContentBlock/Testemonial"));
const ExtremeBlock  = lazy(() => import("../ContentBlock/Extreme"));
const ContentBlockApp = lazy(() => import("../ContentBlockApp"));
const Home = () => {
  return (
    <Container>
      <ScrollToTop />
     
     
      <ContentBlockApp
        direction="left"
       
        icon="graphs.svg"
        id="app"
      />
      <ContentBlock
        direction="left"
       
        icon="graphs.svg"
        id="about"
      />

<HowtoUseBlock
        direction="left"
       
        icon="graphs.svg"
        id="use"
      />

<OurMissionBlock
        direction="left"
       
        icon="graphs.svg"
        id="mission"
      />
      <TestomonialBlock
        direction="left"
       
        icon="graphs.svg"
        id="testemonial"
      />

      <ExtremeBlock
        direction="left"
       
        icon="graphs.svg"
        id="extreme"
      />

   
      <Contact
        title={ContactContent.title}
        content={ContactContent.text}
        id="contact"
      />


    </Container>
  );
};

export default Home;
