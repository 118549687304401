import styled from "styled-components";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled.header`
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 1rem 0.5rem;
  background-color: ${({ isDarkMode }) => (isDarkMode ? "#333" : "#fff")};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  .ant-row-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
  }
`;

export const LogoContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Burger = styled.div`
  @media only screen and (max-width: 768px) {
    display: block;
  }

  display: none;

  svg {
    fill: ${({ isDarkMode }) => (isDarkMode ? "#fff" : "#2e186a")};
  }
`;

export const NotHidden = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Menu = styled.h5`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: ${({ isDarkMode }) => (isDarkMode ? "#fff" : "#000")};
  display: block;
`;

export const CustomNavLinkSmall = styled.div`
  font-size: 1.2rem;
  color: ${({ isDarkMode }) => (isDarkMode ? "#fff" : "#18216d")};
  margin: 0.5rem 2rem;
  transition: color 0.3s ease-in-out;
  display: inline-block;

  &:hover {
    color: rgb(255, 130, 92);
    text-decoration: underline;
  }

  @media only screen and (max-width: 768px) {
    margin: 0.5rem 1rem;
  }
`;

export const Label = styled.span`
  font-weight: 500;
  color: ${({ isDarkMode }) => (isDarkMode ? "#fff" : "#404041")};
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)`
  font-size: 22px;
  cursor: pointer;
`;

export const Span = styled.span`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 130, 92);
    text-underline-position: under;
    text-decoration: rgb(255, 130, 92) wavy underline;
  }
`;
