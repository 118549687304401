import React from "react";
import styled from "styled-components";

// Styled components for simplicity and responsiveness
const PolicyWrapper = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  text-align: center;
  color: #222;
  margin-bottom: 1rem;
`;

const EffectiveDate = styled.p`
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #666;
`;

const Section = styled.div`
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #444;
`;

const SectionText = styled.p`
  font-size: 1rem;
  margin: 0;
`;

const ContactInfo = styled.div`
  margin-top: 2rem;
  font-size: 1rem;
  text-align: center;
  color: #555;

  a {
    color: #1d72b8;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const PrivacyPolicy = () => {
  return (
    <PolicyWrapper>
      <Title>Privacy Policy for Xtream-Generator.com</Title>
      <EffectiveDate>Effective Date: 20/10/2024</EffectiveDate>

      <Section>
        <SectionTitle>1. Information We Collect</SectionTitle>
        <SectionText>
          We do not collect any personal information from users. Our website
          operates without requiring you to provide any personal data.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>2. Use of Information</SectionTitle>
        <SectionText>
          Since we do not collect any personal information, there is no data to
          use for any purpose. Your interactions with Xtream-Generator.com are
          entirely anonymous.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>3. Third-Party Sharing</SectionTitle>
        <SectionText>
          We do not share any user information with third-party services. Your
          privacy is fully protected, as no data is collected or shared.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>4. User Rights</SectionTitle>
        <SectionText>
          As we do not collect any personal information, there are no user
          rights related to accessing, modifying, or deleting data. You can use
          Xtream-Generator.com without any concerns about data privacy.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>5. Data Security</SectionTitle>
        <SectionText>
          Given that no data is collected, we do not need to implement any data
          security measures. Your use of our website is secure and private.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>6. Changes to this Privacy Policy</SectionTitle>
        <SectionText>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page with an updated effective date. We encourage
          you to review this Privacy Policy periodically.
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>7. Contact Information</SectionTitle>
        <SectionText>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at <a href="mailto:Mohcin15000@gmail.com">Mohcin15000@gmail.com</a>.
        </SectionText>
      </Section>

      <ContactInfo>
        Thank you for trusting Xtream-Generator.com!
      </ContactInfo>
    </PolicyWrapper>
  );
};

export default PrivacyPolicy;
